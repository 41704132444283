import { twJoin } from '@troon/tailwind-preset/merge';
import { Icon } from '@troon/icons';
import { UserRewardsLevel } from '../graphql';

type Props = { level: UserRewardsLevel };

export function RewardsCard(props: Props) {
	return (
		<div
			// eslint-disable-next-line tailwindcss/no-arbitrary-value
			class={twJoin(
				'relative flex aspect-[3/2] grow flex-col justify-between gap-2 rounded bg-gradient-to-br p-6 pt-8 text-start text-neutral-950 shadow',
				props.level === UserRewardsLevel.Silver && 'from-neutral-300 to-neutral-600',
				props.level === UserRewardsLevel.Gold && 'from-neutral-300 to-[#E6C885]',
				props.level === UserRewardsLevel.Platinum && 'from-neutral-300 to-[#BDC3D3]',
			)}
		>
			<span
				// eslint-disable-next-line tailwindcss/no-arbitrary-value
				class={twJoin(
					'absolute inset-x-0 top-0 h-8 rounded border-t-8 bg-transparent',
					props.level === UserRewardsLevel.Silver && 'border-neutral-600',
					props.level === UserRewardsLevel.Gold && 'border-[#E6C885]',
					props.level === UserRewardsLevel.Platinum && 'border-[#BDC3D3]',
				)}
			/>
			<div class="flex flex-row items-start justify-between">
				<span
					// eslint-disable-next-line tailwindcss/no-arbitrary-value
					class={twJoin(
						'flex rounded-md px-2 py-1 text-sm font-semibold uppercase',
						props.level === UserRewardsLevel.Silver && 'bg-neutral-600',
						props.level === UserRewardsLevel.Gold && 'bg-[#E6C885]',
						props.level === UserRewardsLevel.Platinum && 'bg-[#BDC3D3]',
					)}
				>
					{pct(data[props.level].discount)} off tee times
				</span>
				<Icon name="logo-square" class="size-12" />
			</div>
			<div class="flex flex-col gap-1">
				<p class="text-2xl font-semibold xl:text-4xl">{data[props.level].title}</p>
				<p class="text-lg uppercase">{points(data[props.level].points)} points</p>
			</div>
		</div>
	);
}

const pct = Intl.NumberFormat('en-US', { style: 'percent' }).format;
const points = Intl.NumberFormat('en-US').format;

const data: Record<UserRewardsLevel, { title: string; points: number; discount: number }> = {
	[UserRewardsLevel.Member]: {
		title: 'Member',
		points: 0,
		discount: 0,
	},
	[UserRewardsLevel.Silver]: {
		title: 'Silver Status',
		points: 2000,
		discount: 0.1,
	},
	[UserRewardsLevel.Gold]: {
		title: 'Gold Status',
		points: 4000,
		discount: 0.15,
	},
	[UserRewardsLevel.Platinum]: {
		title: 'Platinum Status',
		points: 8000,
		discount: 0.2,
	},
};
